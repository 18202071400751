import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="HOW TO PLAY" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      <br/>
        Guess the <strong>WORD</strong> in 6 tries. After each guess.

        <br/>
        Each guess must be a <strong>VALID</strong> 5-letter word. 
        <br/> <br/>
        Hit the <strong>ENTER</strong> button to submit.
        <br/>
        Hit the <strong>DELETE</strong> button to remove.

        <br/><br/>
        After each guess, the color of the tiles will change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="S"
          status="correct"
        />
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="K" />
        <Cell value="E" />
        
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter S is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="present"
        />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell isRevealing={true} isCompleted={true} value="U" status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the word in any spot.
      </p>

     {/* <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="ኢ"
          status="correct"
        />
        <Cell 
         isRevealing={true}
         isCompleted={true}
         value="ት"
         status="correct"/>
        <Cell
        isRevealing={true}
        isCompleted={true}
        value="ዮ"
        status="present"
        />
        <Cell value="ጵ" 
        isRevealing={true}
        isCompleted={true}
        status="present"/>
        <Cell value="ያ"
        isRevealing={true}
        isCompleted={true}
        status="absent" />
        
      </div>
  */}

    </BaseModal>
  )
}
