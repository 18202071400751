import { wordMeaningoftheDay } from '../../lib/words'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const WordMeaningModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="HINT" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-white-500 dark:text-black-300">
      <br/>
      <strong>{wordMeaningoftheDay}</strong>
    </p>
    </BaseModal>
  )
}
